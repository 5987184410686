import React from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import RightLogo from './images/garotopia-transparent-square.png';

function App() {
    return (
        <div className="coming-soon-container">
            <div className="left-section">
                <h1 className="coming-soon-title">Weboldal hamarosan!</h1>
                <p className="coming-soon-subtitle">Addig is keress fel minket más platformokon:</p>
                <div className="social-icons">
                    <a href="https://discord.gg/fDjXnnks2q" className="social-icon"><i className="fab fa-discord"></i>[HUN]Garotopia Discord szerver</a>
                    <a href="https://www.facebook.com/groups/778982325788242" className="social-icon"><i className="fab fa-facebook"></i> [HUN]Garotopia Facebook csoport</a>
                    <a href="https://www.facebook.com/groups/1719257275504735" className="social-icon"><i className="fab fa-facebook"></i> Soulmask Facebook csoport</a>
                    <a href="https://www.twitch.tv/finuchy" className="social-icon"><i className="fab fa-twitch"></i> Finuchy csatornája</a>
                    <a href="https://www.twitch.tv/dombichannel" className="social-icon"><i className="fab fa-twitch"></i> Dombi csatornája</a>
                    <a href="https://www.twitch.tv/red_wolf0012" className="social-icon"><i className="fab fa-twitch"></i> RedWolf csatornája</a>
                </div>
            </div>
            <div className="right-section">
                <img src={RightLogo} alt="Coming Soon" className="right-image" />
            </div>
        </div>
    );
}

export default App;